<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>

        <CRow class="justify-content-center loginmask">
          <CCol md="8">
            <CCard class="p-4">
              <CCardBody>
                <ValidationObserver v-slot="{ invalid }">
                  <CForm @submit.prevent="onPassword">
                    <h1>Passwort vergessen!</h1>
                    <p class="text-muted">Nach Eingabe Ihrer E-Mail-Adresse erhalten sie ein neues Passwort per E-Mail.</p>

                    <ValidationProvider rules="required|email" immediate v-slot="{ errors }">
                      <CInput
                        placeholder="E-Mail-Adresse"
                        autocomplete="username email"
                        :isValid="errors.length ==  0"
                        v-model="username"
                      >
                        <template #prepend-content><CIcon name="cil-envelope-closed"/></template>
                        <template #invalid-feedback>
                          <em class="error invalid-feedback">Keine gültige E-Mail-Adresse.</em>
                        </template>
                      </CInput>
                    </ValidationProvider>

                    <CRow>
                      <CCol col="12" class="text-right">
                        <CButton color="success" class="px-4" :disabled="invalid" type="submit">Neues Passwort</CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </ValidationObserver>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>

    </CContainer>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'app-password',
  data () {
    return {
      username: ''
    }
  },
  computed: {
  },
  methods: {
    onPassword () {
      const payload = {
        email: this.username
      }
      Vue.axios.post('/mitarbeiter/password/forgotten', payload)
        .then((response) => {
          this.$snotify.success('Die E-Mail wurde versendet!', {
            position: 'rightTop',
            timeout: 4000
          })
          this.$router.push({ path: '/security/login' })
        }).catch(() => {
          this.$snotify.error('Unbekannte E-Mail-Adresse!', {
            position: 'rightTop',
            timeout: 4000
          })
        })
    }
  }
}
</script>

<style lang="scss">
  div.loginmask {
    div.card-body {
      padding: 0;
    }
  }
</style>
